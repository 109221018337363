import { render, staticRenderFns } from "./TheAdminSidebar.vue?vue&type=template&id=5f963da6&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VIcon,VListItem,VListItemAction,VListItemTitle,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f963da6')) {
      api.createRecord('5f963da6', component.options)
    } else {
      api.reload('5f963da6', component.options)
    }
    module.hot.accept("./TheAdminSidebar.vue?vue&type=template&id=5f963da6&", function () {
      api.rerender('5f963da6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/admin/components/TheAdminSidebar.vue"
export default component.exports