var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        app: "",
        clipped: "",
        "mobile-breakpoint": "600",
        color: "sidebarLight"
      }
    },
    [
      _c(
        "v-list-item",
        { attrs: { to: { name: "admin-add-org" } } },
        [
          _c("v-list-item-action", [_c("v-icon", [_vm._v("apartment")])], 1),
          _c("v-list-item-title", [_vm._v("Add Organization")])
        ],
        1
      ),
      _c(
        "v-list-item",
        { attrs: { to: { name: "admin-clone-space" } } },
        [
          _c("v-list-item-action", [_c("v-icon", [_vm._v("dashboard")])], 1),
          _c("v-list-item-title", [_vm._v("Clone Space")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }